"use client";

import React, { useEffect, useState, type JSX } from "react";
import { useRouter } from "next/navigation";
import CTALink from "@/components/CTA/ctaLink";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import isUserLoggedIn from "@/lib/auth/isUserLoggedIn";
import { NavProps, NavData } from "../types";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { useInView } from "react-intersection-observer";
import { hyphenateForTagular } from "@/utils/tagular/helpers";

function CTA({
  userLoggedIn,
  location,
  html_id,
}: {
  userLoggedIn: boolean;
  location: string | undefined;
  html_id: string | undefined;
}) {
  if (userLoggedIn) {
    return (
      <CTALink
        type="brand"
        url="/search"
        label="Search edX courses"
        icon="search"
        icon_position="left"
      />
    );
  }
  return (
    <CTALink
      type="bordered"
      url="https://authn.edx.org/register"
      label="Create an account"
      location={location ?? ""}
      html_id={html_id}
    />
  );
}

function InternalNavItem({
  item,
  index,
  location,
}: {
  item: NavData;
  index: number;
  location: string | undefined;
}): JSX.Element {
  const element: ElementViewed["webElement"] = {
    elementType: ElementType.Link,
    htmlId: item.id,
    location: location ?? "",
    position: String(index),
    text: item.name,
    name: hyphenateForTagular(item.name),
  };

  const url = `#${item.id}`;

  const { elementViewed, elementClicked } = useElementEvents({
    webElement: element,
  });

  const { inView, ref } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const handleClick = () => {
    elementClicked();
  };

  return (
    <a
      ref={ref}
      onClick={handleClick}
      key={index}
      href={url}
      className="underline opacity-80 hover:opacity-100 capitalize text-white hover:text-white mr-6"
    >
      {item.name}
    </a>
  );
}

function InternalNavList({
  navigation,
  location,
}: {
  navigation: NavData[];
  location: string | undefined;
}): JSX.Element[] {
  return navigation.map((item, index) => (
    <InternalNavItem
      item={item}
      index={index}
      key={index}
      location={location}
    />
  ));
}

export default function InternalNav({ data, location, html_id }: NavProps) {
  const router = useRouter();
  const userLoggedIn = isUserLoggedIn();

  const navigation: NavData[] = data || [];
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    navigation?.at(0)?.name,
  );

  if (navigation?.length < 1) {
    return null;
  }

  const findNavItem = (value: string) =>
    navigation.find((navItem) => navItem.name === value);

  const handleSelect = (value: string) => {
    setSelectedOption(value);
    if (value.includes("/")) {
      router.push(value);
    }
    const navItem = findNavItem(value);
    if (navItem) {
      router.push(`#${navItem.id}`);
    }
  };

  return (
    <div className="fullwidth bg-secondary text-primary-foreground p-8 sticky top-0 z-50">
      <div className="container">
        <div
          className={`lg:flex hidden text-sm items-center ${
            userLoggedIn ? "justify-between" : ""
          }`}
        >
          <ClientOnly>
            <InternalNavList navigation={navigation} location={location} />
          </ClientOnly>
          <CTA
            userLoggedIn={userLoggedIn}
            location={location}
            html_id={html_id}
          />
        </div>
        <div className="lg:hidden p-4 w-full">
          <Select value={selectedOption} onValueChange={handleSelect}>
            <SelectTrigger className="no-underline text-white hover:text-white">
              <SelectValue placeholder={selectedOption} />
            </SelectTrigger>
            <SelectContent>
              {/* Must find id's of selectable items based within the page content */}
              {navigation.map((item, index) => (
                <SelectItem key={index} value={item.name} className="md:mx-2">
                  {item.name}
                </SelectItem>
              ))}
              <SelectItem
                value={
                  userLoggedIn ? "/search" : "https://authn.edx.org/register"
                }
                className="md:mx-2"
              >
                {userLoggedIn ? "Search edX courses" : "Create an account"}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
}
