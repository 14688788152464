import { Skeleton } from "..";

export default function FullSkeleton() {
  return (
    <div className="container">
      <div className="flex space-x-2 mt-4">
        <Skeleton className="w-16 h-6 rounded-none" />
        <Skeleton className="w-16 h-6 rounded-none" />
        <Skeleton className="w-16 h-6 rounded-none" />
      </div>
      <Skeleton className="w-full h-80 rounded-none mt-4" />
      <div className="flex flex-col space-y-4 mt-4">
        <Skeleton className="w-1/3 h-16 rounded-none" />
        <Skeleton className="w-5/6 h-48 rounded-none" />
        <Skeleton className="w-1/3 h-16 rounded-none" />
        <Skeleton className="w-5/6 h-48 rounded-none" />
        <Skeleton className="w-1/3 h-16 rounded-none" />
        <Skeleton className="w-5/6 h-48 rounded-none" />
      </div>
    </div>
  );
}
