import { cn } from "@/lib/utils";
import { Skeleton } from "..";

export default function CardSkeleton({
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="w-48 h-56 flex flex-col rounded-xl border-4 border-primary/10 animate-pulse">
      <Skeleton className="w-full h-1/3 rounded-none mb-2" />
      <Skeleton className=" w-3/4 h-5 rounded-none mb-2 ml-2" />
      <Skeleton className="w-2/3 h-5 rounded-none mb-4 ml-2" />
      <Skeleton className="w-1/3 h-5 rounded-none mb-2 ml-2" />
      <Skeleton className="w-1/3 h-4 rounded-none mt-auto mb-2 ml-2" />
    </div>
  );
}
