"use client";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import CTALink from "@/components/CTA/ctaLink";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { NavProps, NavData } from "../types";

export default function FlexNav({ data, cta }: NavProps) {
  const path = usePathname();
  const router = useRouter();
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isAtTop, setIsAtTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const rect = stickyRef.current.getBoundingClientRect();
        // Check if the element's top is at or above the viewport's top
        setIsAtTop(rect.top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Initial check in case the element is already at the top
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!data) {
    return null;
  }

  const navigation: NavData[] = data;
  const activeValue = navigation.find((item) => item.isActive);
  // ensure program slug isn't replaced if the tab slug isn't present in the url
  const prefix =
    !activeValue || !path.includes(activeValue.id) ? `${path}/` : "";

  const handleSelect = (value: string) => {
    router.push(`${prefix}${value}`);
  };

  return (
    <div
      ref={stickyRef}
      className="flex w-full bg-putty-light lg:bg-white justify-center sticky top-0 z-50"
    >
      <div
        className={`container w-full lg:flex hidden text-sm items-center justify-between`}
      >
        <div className="py-8">
          {navigation.map((item, index) => (
            <Link
              key={index}
              href={`${prefix}${item.id}`}
              className={`p-4 border-b-2 hover:bg-putty-light ${item.isActive ? "border-primary" : ""}`}
            >
              {item.name}
            </Link>
          ))}
        </div>
        {cta && isAtTop && (
          <CTALink
            type="brand"
            size="xl"
            icon="arrow-right"
            className="mr-6"
            label={cta.label}
            url={cta.url}
          />
        )}
      </div>
      <div className="lg:hidden p-4 w-full flex flex-col sm:flex-row items-center justify-between gap-4 [&>button]:bg-white [&>button]:text-lg">
        <Select onValueChange={handleSelect}>
          <SelectTrigger>
            <SelectValue placeholder={activeValue?.name} />
          </SelectTrigger>
          <SelectContent>
            {navigation.map((item, index) => (
              <SelectItem key={index} value={item.id} className="md:mx-2">
                {item.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {cta && isAtTop && (
          <CTALink
            type="brand"
            size="lg"
            className="shrink-0 w-full sm:w-auto"
            label={cta.label}
            url={cta.url}
          />
        )}
      </div>
    </div>
  );
}
