import { cn } from "@/lib/utils";
import FullSkeleton from "./variants/fullPageSkeleton";
import Button from "./variants/button";
import Card from "./variants/card";

interface SkeletonProps {
  variant?: "fullPage" | "button" | "card";
}

function Skeleton({
  className,
  variant,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & SkeletonProps) {
  const baseClasses = "animate-pulse rounded-md bg-primary/10";

  switch (variant) {
    case "fullPage":
      return <FullSkeleton />;
    case "button":
      return <Button className={cn(baseClasses, className)} {...props} />;
    case "card":
      return <Card className={cn(baseClasses, className)} {...props} />;
    default:
      return <div className={cn(baseClasses, className)} {...props} />;
  }
}

export { Skeleton };
