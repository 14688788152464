import {
  DegreeDetailRedesignTrafficProps,
  UseDegreeDetailRedesignTraffic,
} from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";

const CUSTOM_RULE_ID = "degreeDetailPageRedesignTest";

const useDegreeDetailRedesignTraffic: UseDegreeDetailRedesignTraffic = () => {
  const { isLoading, value } = useMonarchRule<{
    data: DegreeDetailRedesignTrafficProps;
    variantCustomId: string;
  }>(CUSTOM_RULE_ID, {});

  return {
    isLoading,
    ...value?.data,
  };
};

export default useDegreeDetailRedesignTraffic;
