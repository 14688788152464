"use client";
import React, { FC } from "react";
import Image from "next/image";
import { HeroProps } from "../types";
import { Skeleton } from "@/components/ui/skeleton";
import useHeroPersonalization from "@/monarch/rules/heroPersonalization/useHeroPersonalization";
import { usePathname } from "next/navigation";

const Loader: FC = () => {
  return (
    <>
      <Skeleton className="h-8 w-96 mb-4 mt-10 rounded-none-sm bg-slate-100/20" />
      <Skeleton className="h-8 w-96 mb-4 rounded-none-sm bg-slate-100/20" />
      <Skeleton className="h-8 w-80 mb-32 rounded-none-sm bg-slate-100/20" />

      <Skeleton className="h-4 w-96 mb-4 rounded-none-sm bg-slate-100/20" />
      <Skeleton className="h-4 w-80 mb-4 rounded-none-sm bg-slate-100/20" />
      <Skeleton className="h-4 w-64 mb-4 rounded-none-sm bg-slate-100/20" />

      <div className="flex flex-row mt-10 space-x-4 mb-10">
        <Skeleton className="h-11 w-64 mb-4 rounded-none bg-slate-100/20" />
        <Skeleton className="h-11 w-28 mb-4 rounded-sm bg-slate-100/20" />
      </div>
    </>
  );
};

/**
 * example input: "font-bold text-3xl lg:text-4xl"
 * output: "[&_h1]:font-bold [&_h1]:text-3xl lg:[&_h1]:text-4xl"
 */
const getTitleClassNames = (titleClassName: string | undefined) =>
  titleClassName
    ?.split(" ")
    .map((className) => {
      if (className.includes(":")) {
        return className
          .split(":")
          .map((item, index) => (index === 1 ? `[&_h1]:${item}` : item))
          .join(":");
      }
      return `[&_h1]:${className}`;
    })
    .join(" ") || "";

const Home = ({ children, image, titleClassName }: HeroProps) => {
  const { value, isLoading } = useHeroPersonalization();
  const titleClassNames = getTitleClassNames(titleClassName);

  const pathname = usePathname();
  const isProfEdPage = pathname === "/professional-education";

  return (
    <div
      className={
        image
          ? `relative md:px-4 pt-16 pb-8 lg:p-20 mx-break-out lg:bg-none ${!isProfEdPage ? "bg-primary-gradient from-secondary to-primary-500" : ""} [&_p]:text-white [&_p]:text-xl lg:[&_p]:text-[28px] [&_p]:font-bold ${titleClassNames || "[&_h1]:text-white [&_h1]:elm-d4 [&_h1]:!leading-none [&_h1]:italic [&_h1]:font-black [&_h1]:mb-8"}`
          : titleClassNames
      }
    >
      {image?.sourceUrl && (
        <Image
          src={image.sourceUrl}
          priority={true}
          layout="fill"
          loading="eager"
          objectFit="cover"
          objectPosition="center"
          alt={image.altText ?? "hero background"}
          className={`absolute m-0 z-[-1] ${isProfEdPage ? "block" : "hidden lg:block"}`}
        />
      )}
      <div className="container grid grid-cols-1 lg:grid-cols-2 pb-8">
        <div>{isLoading ? <Loader /> : (value ?? children)}</div>
      </div>
    </div>
  );
};

export default Home;
