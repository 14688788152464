"use client";

import Cookies from "js-cookie";
import { LOCATION_OVERRIDE_COOKIE, USER_LOCATION_COOKIE } from "./constants";

// This cookie is currently set automatically as soon as you enter edx.org
// TODO: Find our where this cookie is coming from and if we can set it, or find an alternative to get the user's country
// This is important to filter the results in algolia to exclude some results that are not available in certain regions
const getUserLocation = () =>
  Cookies.get(LOCATION_OVERRIDE_COOKIE) || Cookies.get(USER_LOCATION_COOKIE);
export default getUserLocation;
