import React, { useState, useLayoutEffect, MutableRefObject } from "react";

//This hook is meant to be used along with line-clamp tailwind classes
const useTruncatedElement = (ref: MutableRefObject<HTMLElement | null>) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isReadingMore, setIsReadingMore] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      setIsTruncated(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }, [ref]);

  return {
    isTruncated,
    isReadingMore,
    setIsReadingMore,
  };
};

export default useTruncatedElement;
