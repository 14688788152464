function decodeMalformedText(text: string | undefined): string {
  if (!text) {
    return "";
  }
  const replacements: { [key: string]: string } = {
    "â": "'", // Right single quotation mark
    "â€œ": "“", // Left double quotation mark
    "â€": "”", // Right double quotation mark
    "â": "—", // Em dash
    "â": "–", // En dash
    "â€¦": "…", // Ellipsis
    "Ã©": "é", // e with acute
    "Ã¨": "è", // e with grave
    "Ã¯": "ï", // i with diaeresis
    "Ã¶": "ö", // o with diaeresis
    "Ã¼": "ü", // u with diaeresis
    "Ã¤": "ä", // a with diaeresis
    Ã: "à", // a with grave
    "â€": "€", // Euro sign
    "Ã±": "ñ", // n with tilde
    "Ã§": "ç", // c with cedilla
    Â: "", // empty character
    "â¢": "™", // Trademark symbol
    // Additional mappings can be added here as needed
  };

  let updatedText = text;

  Object.keys(replacements).forEach((wrong) => {
    updatedText = updatedText.replace(
      new RegExp(wrong, "g"),
      replacements[wrong],
    );
  });

  return updatedText;
}

export default decodeMalformedText;
