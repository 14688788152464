"use client";

import getUserLocation from "@/lib/location/getUserLocationFromClient";
import { checkPersonalizationFlag } from "./checkPersonalizationFlag";

export function validateLocation(
  allowedIn: string[],
  blockedIn: string[],
): boolean {
  if (checkPersonalizationFlag()) {
    // If the personalized experience is disabled, return the original product data
    return true;
  }

  const userLocation = getUserLocation() || "US";

  const isValidLocation =
    (allowedIn.length === 0 && blockedIn.length === 0) ||
    ((allowedIn?.includes(userLocation) || allowedIn.length === 0) &&
      (!blockedIn?.includes(userLocation) || blockedIn.length === 0));

  return isValidLocation;
}
