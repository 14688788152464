"use client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { HeroProps } from "../types";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import InternalNav from "@/components/InternalNav";
import useTruncatedElement from "@/hooks/useTrancatedElement";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { ElementType, PageNames } from "@/constants/tagular/main";
import { getPageType, hyphenateForTagular } from "@/utils/tagular/helpers";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { useInView } from "react-intersection-observer";

export default function NoImageHero({
  title,
  titleOverride,
  path,
  subnavItems,
  hasBreadcrumbs = true,
  children,
  location,
  html_id,
  text = "",
}: HeroProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { ref: inViewRef, inView } = useInView();

  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      ref.current = node as HTMLDivElement | null;
      inViewRef(node);
    },
    [inViewRef],
  );

  const { isTruncated, isReadingMore, setIsReadingMore } =
    useTruncatedElement(ref);
  const [pageType, setPageType] = useState("unknown");

  const truncateText = isReadingMore ? "Show less" : "Read more";

  const webElement: ElementViewed["webElement"] = {
    elementType: ElementType.Entry,
    htmlId: html_id,
    location: location,
    text: text,
    position: "1",
    name: hyphenateForTagular(text),
  };

  const { elementViewed, elementClicked } = useElementEvents({ webElement });

  useEffect(() => {
    // For now, only send ElementViewed/ElementClicked eventing data if it's from course landing shop page.
    const page = getPageType();
    if (page) {
      setPageType(page);
    }
    if (inView && pageType === PageNames.ShopPage) {
      elementViewed();
    }
  }, [pageType, inView]);

  const handleClick = () => {
    if (pageType === PageNames.ShopPage) {
      elementClicked();
    }
  };

  return (
    <>
      <div className="fullwidth pb-12 pt-20 md:pt-16 bg-cover bg-center bg-[url(/bg-images/learn-page-header.webp)]">
        <div className="mx-auto lg:container [&>*]:text-white">
          {hasBreadcrumbs && path && (
            <Breadcrumbs variant="light" path={path} />
          )}
          <h1 className="text-learn-hero-heading lg:text-learn-hero-heading-large lg:leading-learn-hero-line-height-large mb-6 font-extrabold tracking-tight">
            {titleOverride || title}
          </h1>
          <div
            ref={setRefs}
            onClick={handleClick}
            className={`break-words [&>ul>li>p]:text-white [&>*]:text-white text-xl ${!isReadingMore && "line-clamp-2"}`}
          >
            {children}
          </div>

          {isTruncated && children && (
            <button onClick={() => setIsReadingMore((prev) => !prev)}>
              {truncateText}
            </button>
          )}
        </div>
      </div>
      <InternalNav
        variant="primary"
        data={subnavItems}
        location={location}
        html_id={html_id}
      />
    </>
  );
}
